import { Section, Spinner, Tabs, TwoColumnDetails } from 'common'
import { WeeklyHireTable } from 'components/Invoices'
import { EDInvoicesTable } from 'components/Invoices/EdInvoices/EdInvoicesTable'
import {
	JobTaskTable,
	HandoverFrom,
	JobVariationsTableTable,
} from 'components/Jobs'
import { Notes } from 'components/Notes'
import { AppRoutes } from 'config'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { MobileScaffoldJobServices } from 'services'

const items = [
	{ label: 'Tasks', id: 1 },
	{ label: 'Mobile Scaffold Files', id: 2 },
	// { label: 'Visits', id: 2 },
	// { label: 'Visit Timesheets', id: 3 },
	// { label: 'Scaffold Tags', id: 4 },
	// { label: 'Weekly Hire Invoices', id: 5 },
	// { label: 'E&D Invoices', id: 6 },
	// { label: 'K2 Files', id: 7 },
	// { label: 'Quoted hours vs. Actual hours', id: 8 },
]

export const JobDetails = () => {
	const { id } = useParams()
	const location = useLocation()
	const jobId = parseInt(id || '') || undefined
	const [tabIndex, setTabIndex] = useState(1)
	const {
		data: mobileScaffoldJob,
		isLoading,
		enableCreateUpdate,
	} = MobileScaffoldJobServices.useMobileScaffoldJobById(jobId)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [handover, setHandover] = useState<any>({})
	const { data, isLoading: handoverLoading } =
		MobileScaffoldJobServices.useHandoverByJobId(jobId)
	useEffect(() => {
		if (!data) return
		setHandover(data)
	}, [data])

	if (isLoading || handoverLoading) {
		return <Spinner />
	}

	return (
		<>
			<div className="w-full mx-auto mt-8 mb-28">
				<TwoColumnDetails
					heading="Mobile Scaffold Job Details"
					editBtn="Edit Mobile Scaffold Job"
					editLink={{
						to: AppRoutes.privateRoutes.K2Edit.replace(':id', id || ''),
						state: {
							background: location,
							name: 'editJob',
						},
					}}
					isEditable={enableCreateUpdate}>
					<Section
						title="Mobile Scaffold Job #"
						content={mobileScaffoldJob.job_num}
					/>
					<Section
						title="Mobile Scaffold Job Type"
						content={mobileScaffoldJob.job_type}
					/>
					<Section title="Client" content={mobileScaffoldJob.client_name} />
					<Section title="Site" content={mobileScaffoldJob.site} />
					<Section
						title="Delivery Date"
						content={
							mobileScaffoldJob.start_date
								? moment(mobileScaffoldJob.start_date).format('DD/MM/YYYY')
								: 'N/A'
						}
					/>
					<Section
						title="Return Date"
						content={
							mobileScaffoldJob.end_date
								? moment(mobileScaffoldJob.end_date).format('DD/MM/YYYY')
								: 'N/A'
						}
					/>
					<Section
						title="Mobile Scaffold Job Status"
						content={mobileScaffoldJob.job_status}
					/>
					<Section title="Status" content={mobileScaffoldJob.status} />

					<Section
						title="Requirements"
						content={mobileScaffoldJob.descriptionOfQuote}
					/>

					<Section title="Notes" content={mobileScaffoldJob.notes} />
				</TwoColumnDetails>{' '}
				<div className="px-8">
					<Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={items} />
				</div>
				{tabIndex === 1 && (
					<>
						<JobTaskTable is_mobile_scaffold job_id={jobId} />
						{/* <JobVariationsTableTable job_id={jobId} client_id={k2.client_id} /> */}
					</>
				)}
				{tabIndex === 2 && <Notes type="mobileScaffold" id={id || ''} />}
			</div>
		</>
	)
}
