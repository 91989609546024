import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'
import { leaveRow } from 'models'

export const useCreateLeave = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest } = useApi()
	const queryClient = useQueryClient()

	const createLeave = async (leaveId: number, leave: leaveRow) => {
		try {
			const response = await putRequest(
				AppRoutes.serverLeavesRoutes.createLeave,
				leave,
				leaveId
			)
			queryClient.refetchQueries(['leaves'])
			queryClient.refetchQueries(['approvedLeaves'])
			queryClient.refetchQueries(['leavesStaff'])
			showSuccess('Leave created successfully')
			return response
		} catch (error) {
			showError('Something went wrong creating Leave')
			throw new Error((error as Error).message)
		}
	}
	return { createLeave }
}
