import { CheckIcon } from '@heroicons/react/24/solid'
import { Button, ConfirmationDialog } from 'common'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { DayTimesheetServices } from 'services'

interface ApproveDayTimesheetProps {
	dayTimesheets: any
}

export const ApproveDayTimesheet = ({
	dayTimesheets,
}: ApproveDayTimesheetProps) => {
	const { updateDayTimesheet } =
		DayTimesheetServices.useUpdateDayTimesheetById()
	const userState = useSelector((store: AppStore) => store.user)
	const handleApproveDayTimesheets = async () => {
		console.log(dayTimesheets)
		const selectedDayTimesheets = dayTimesheets.map((row: any) => ({
			id: row.id,
			approved_by: userState.name,
		}))
		console.log(selectedDayTimesheets)
		for (const dayTimesheet of selectedDayTimesheets) {
			await updateDayTimesheet(dayTimesheet.id, dayTimesheet)
		}
	}

	return (
		<ConfirmationDialog
			icon="info"
			title={'Approve DayTimesheet'}
			body="Are you sure you wish to approve this dayTimesheet?"
			triggerButton={
				<Button
					variant="approve"
					size="sm"
					startIcon={
						<CheckIcon
							className="-ml-0.5 mr-2 h-4 w-4 text-white"
							aria-hidden="true"
						/>
					}>
					Approve Day Timesheets
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					variant="approve"
					onClick={async () => handleApproveDayTimesheets()}>
					Approve
				</Button>
			}
		/>
	)
}
