import React from 'react'

interface ImageData {
	tag_photo?: string
	handover_certificate_photo?: string
	pick_list_photo?: string
	scaffold_photo_1?: string
	scaffold_photo_2?: string
	scaffold_photo_3?: string
}

interface ImageGridProps {
	imageData: ImageData
}

const imageTitles: any = {
	tag_photo: 'Tag Photo',
	handover_certificate_photo: 'Handover Certificate',
	pick_list_photo: 'Pick List',
	scaffold_photo_1: 'Photo',
	scaffold_photo_2: 'Photo',
	scaffold_photo_3: 'Photo',
}

const ImageGrid: React.FC<ImageGridProps> = ({ imageData }) => {
	return (
		<div className="grid grid-cols-6 gap-4 px-10">
			{Object.entries(imageData).map(([propertyName, imageUrl], index) =>
				imageUrl ? (
					<div key={index}>
						<div className="rounded-xl">
							<a href={imageUrl} target="_blank" rel="noopener noreferrer">
								<img
									src={imageUrl}
									alt={`${imageTitles[propertyName]} Image`}
									className="w-full h-auto rounded-lg"
								/>
							</a>
						</div>
						<p className="text-center">{imageTitles[propertyName]}</p>
					</div>
				) : null,
			)}
		</div>
	)
}

export default ImageGrid
