import { Spinner, ErrorComponent, CreateFile } from 'common'
import {
	MessageCard,
	NewsFeedMessage,
} from 'components/NewsFeed/NewsFeedMessage'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppStore } from 'redux/store'
import {
	NewsFeedMessageServices,
	NewsFeedServices,
	StaffServices,
} from 'services'
import { useCreateNewsFeedMessage } from 'services/newsfeed_message'
export const NewsFeedDetails = () => {
	const { id } = useParams()
	const newsFeedId = id || '-1'
	const { data: newsFeedData, isLoading: newsFeedLoading } =
		NewsFeedServices.useNewsFeedsById(newsFeedId)
	const { data: newsFeedMessages, isLoading } =
		NewsFeedMessageServices.useNewsFeedMessagesByNewsFeedID(newsFeedId)
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const { createNewsFeedMessage } = useCreateNewsFeedMessage()
	const [newMessage, setNewMessage] = useState('')
	const [uploadedFile, setUploadedFile] = useState('')
	const userState = useSelector((store: AppStore) => store.user)
	const loggedInStaffMember = staffData?.find(
		(staff: any) => staff.email === userState.email
	)

	const handleSendMessage = async () => {
		// Check if the input is empty
		if (!newMessage) return
		// Create a new message object
		const newMessageObject = {
			newsfeed_id: newsFeedId,
			from: loggedInStaffMember?.id,
			attachment_url: uploadedFile,
			message: newMessage,
			status: 'Active',
		}

		await createNewsFeedMessage(newMessageObject)

		// Clear the input after sending the message
		setNewMessage('')
		setUploadedFile('')
	}

	// A utility function to get the current date and time in the desired format
	const getCurrentDateTime = () => {
		const now = new Date()

		const formattedDateTime = `${String(now.getDate()).padStart(
			2,
			'0'
		)}/${String(now.getMonth() + 1).padStart(
			2,
			'0'
		)}/${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}:${String(
			now.getMinutes()
		).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`

		return formattedDateTime
	}

	const getColorBySender = (sender: string): string => {
		const hashString = (str: string): number => {
			let hash = 8941
			for (let i = 0; i < str?.length; i++) {
				hash = (hash << 5) - hash + str.charCodeAt(i)
				hash |= 0 // Convert to 32-bit integer
			}
			return hash
		}

		const pastelColors = [
			'bg-blue-100',
			'bg-purple-100',
			'bg-green-100',
			'bg-yellow-100',
			'bg-pink-100',
			'bg-indigo-100',
			'bg-teal-100',
			'bg-orange-100',
			'bg-red-100',
			'bg-cyan-100',
			'bg-amber-100',
			'bg-lime-100',
			'bg-emerald-100',
			'bg-gray-100',
			'bg-blue-200',
			'bg-purple-200',
			'bg-green-200',
			'bg-yellow-200',
			'bg-pink-200',
			'bg-indigo-200',
			'bg-teal-200',
			'bg-orange-200',
			'bg-red-200',
			'bg-cyan-200',
			'bg-amber-200',
			'bg-lime-200',
			'bg-emerald-200',
			'bg-gray-200',
			// Add more colors as needed
		]

		const colorIndex = Math.abs(hashString(sender)) % pastelColors.length

		return pastelColors[colorIndex] || 'bg-gray-200' // Default color if sender is not found
	}

	const setFieldValue = (field: string, value: string) => {
		setUploadedFile(value)
	}

	console.log(newsFeedData)
	console.log(newsFeedMessages)

	if (isLoading || newsFeedLoading) {
		return (
			<div className="w-full h-48 flex justify-center items-center">
				<Spinner />
			</div>
		)
	}

	// if (error) {
	// 	return <ErrorComponent />
	// }
	return (
		<div className="flex flex-col items-center justify-center w-full min-h-screen mx-auto mt-8 mb-28 px-4">
			<div className="bg-slate-600 text-white p-4 mb-4 rounded-lg shadow-md w-1/3">
				<p>
					<strong>Subject:</strong> {newsFeedData.subject}
				</p>
				<p>
					<strong>Date Time:</strong>{' '}
					{new Date(newsFeedData.updatedAt).toLocaleString('en-AU')}
				</p>
				<p>
					<strong className="mr-1">From:</strong>
					<span
						className={`inline-block text-slate-700 mx-1 my-1 px-2 py-0.5 rounded-full ${getColorBySender(
							newsFeedData?.fromStaffData?.staff_name
						)}`}>
						{newsFeedData?.fromStaffData?.staff_name}
					</span>
				</p>
				<p>
					<strong className="mr-1">To:</strong>
					{newsFeedData.to?.map((to: any, index: any) => {
						const staffMember = staffData?.find((staff: any) => staff.id === to)
						return (
							<span
								key={index}
								className={`inline-block text-slate-700 mx-1 my-1 px-2 py-0.5 rounded-full ${getColorBySender(
									staffMember?.staff_name || 'Unknown'
								)}`}>
								{staffMember?.staff_name || 'Unknown'}
							</span>
						)
					})}
				</p>
				<p>
					<strong className="mr-1">Status:</strong>
					<span
						className={`inline-block text-slate-700 mx-1 my-1 px-2 py-0.5 rounded-full bg-emerald-300`}>
						{newsFeedData.status}
					</span>
				</p>
			</div>
			<div className="flex flex-col">
				{newsFeedMessages.map((message: any) => (
					<div
						key={message.id}
						className={`${getColorBySender(
							message.fromStaffData.staff_name
						)} p-4 mb-4 rounded-lg shadow-md w-full hover:shadow-lg`}>
						<MessageCard message={message} />
					</div>
				))}
			</div>
			<div className="flex items-center mt-4 w-1/3">
				<textarea
					className="w-full p-4 border rounded-md"
					rows={3}
					placeholder="Type your message..."
					value={newMessage}
					onChange={(e) => setNewMessage(e.target.value)}
				/>
			</div>
			<div className="flex justify-center items-center mt-4 w-1/3">
				<div>
					<CreateFile
						field="setUploadedFile"
						setFieldValue={setFieldValue}
						value={uploadedFile}
					/>
				</div>
				<div>
					<button
						className="ml-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
						onClick={handleSendMessage}>
						Send
					</button>
				</div>
			</div>
		</div>
	)
}
