import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useCreateNewsFeed = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest, getRequest } = useApi()
	const queryClient = useQueryClient()

	const createNewsFeed = async (newsfeed: unknown) => {
		try {
			const response = await putRequest(
				AppRoutes.serverNewsFeedRoutes.createNewsFeed,
				newsfeed,
			)
			queryClient.refetchQueries(['newsfeed'])
			queryClient.refetchQueries(['newsfeed_staff'])
			showSuccess('NewsFeed created successfully')
			return response
		} catch (error) {
			showError('Something went wrong creating NewsFeed File')
			throw new Error((error as Error).message)
		}
	}
	return { createNewsFeed }
}