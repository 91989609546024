import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useUpdateNewsFeedMessage = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest, getRequest } = useApi()
	const queryClient = useQueryClient()

	const updateNewsFeedMessage = async (newsfeed: unknown, id: number | string) => {
		try {
			const response = await postRequest(
				AppRoutes.serverNewsFeedMessageRoutes.updateNewsFeedMessage,
				newsfeed,
				id,
			)
			queryClient.refetchQueries(['newsfeedmessage'])
			queryClient.refetchQueries(['newsfeedmessage_newsfeed'])
			showSuccess('News feed messages updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating news feed messages')
			throw new Error((error as Error).message)
		}
	}
	return { updateNewsFeedMessage }
}
