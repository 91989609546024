import { Formik } from 'formik'
import { SideModal, Dropdown, DateSelect, TextArea } from 'common'

import { LeaveServices, StaffServices } from 'services'
import { OptionsForDropdown } from 'utilities'

interface CreateLeaveFormProps {
	heading: string
	open: boolean
	setOpen: (open: boolean) => void
	formType: 'create'
	leaveId: number
}

export function CreateLeaveForm({
	heading,
	open,
	setOpen,
	formType = 'create',
	leaveId,
}: CreateLeaveFormProps) {
	const createLeaveMutation = LeaveServices.useCreateLeave()
	const staffQuery = StaffServices.useStaff()

	const leaveOptions = [
		{ value: 'Annual', label: 'Annual' },
		{ value: 'Sick', label: 'Sick' },
		{ value: 'Bereavement', label: 'Bereavement' },
		{ value: 'Leave Without Pay', label: 'Leave Without Pay' },
		{ value: 'Other', label: 'Other' },
	]

	return (
		<div>
			<Formik
				initialValues={{
					start_date: null,
					end_date: null,
					staff_id: 0,
					type: '',
					comments: '',
				}}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					setSubmitting(true)

					await createLeaveMutation.createLeave(leaveId, {
						approvedBy: '',
						comments: values.comments,
						endDate: values.end_date,
						staff_id: values.staff_id,
						startDate: values.start_date,
						status: 'Pending',
						totalDays: 0,
						type: values.type,
					})
					setSubmitting(false)
					setOpen(false)
					resetForm()
				}}>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					setFieldTouched,
				}) => (
					<SideModal
						heading={heading}
						open={open}
						setOpen={setOpen}
						handleSubmit={handleSubmit}
						isLoading={isSubmitting}
						formType={formType}>
						<div className="flex items-center">
							<Dropdown
								label="Staff"
								id="staff_id"
								value={values.staff_id}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								options={OptionsForDropdown(
									staffQuery.data,
									'id',
									'staff_name'
								)}
							/>
							<Dropdown
								label="Leave Type"
								id="type"
								options={leaveOptions}
								value={values.type}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
							/>
						</div>
						<div className="flex items-center">
							<DateSelect
								title="Start Date"
								id="start_date"
								value={values.start_date}
								onChange={setFieldValue}
							/>
							<DateSelect
								title="Finish Date"
								id="end_date"
								value={values.end_date}
								onChange={setFieldValue}
							/>
						</div>
						<div>
							<TextArea
								title="Comments"
								id="comments"
								type="text"
								handleChange={handleChange}
								handleBlur={handleBlur}
								value={values.comments}
							/>
						</div>
					</SideModal>
				)}
			</Formik>
		</div>
	)
}
