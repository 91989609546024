import { useParams } from 'react-router-dom'

import { TwoColumnDetails, Section } from 'common/Details'
import { Spinner, ErrorComponent } from 'common'
import { ScaffoldRegisterServices } from 'services'
import { dateFormat } from 'utilities'
import { AppFilesComponents } from 'components'
import ImageGrid from 'components/ScaffoldRegister/ImageGrid'
import moment from 'moment'

export const DetailsPage = () => {
	const { id } = useParams()

	const { data, error, isLoading } = ScaffoldRegisterServices.useGetTagById(
		Number(id),
	)

	if (isLoading) {
		return (
			<div className="w-full h-48 flex justify-center items-center">
				<Spinner />
			</div>
		)
	}

	if (error) {
		return <ErrorComponent />
	}

	return (
		<div className="w-full mx-auto mt-8 mb-28">
			{data && (
				<TwoColumnDetails heading="Scaffold Register Details">
					<Section title="K2" content={data?.jobData?.job_num} />
					<Section title="Tag #" content={data?.tag_no} />
					<Section title="Description" content={data.description} />
					<Section title="" content={null} />
					<Section title="Area" content={data.area} />
					<Section title="Sub Area" content={data.sub_area} />
					<Section
						title="Last Inspection"
						content={
							data.inspection_date
								? dateFormat.format(moment(data.inspection_date, 'DD/MM/YYYY').toDate())
								: ''
						}
					/>
					<Section
						title="Inspection Due"
						content={
							data.next_inspection_date
								? dateFormat.format(moment(data.next_inspection_date, 'DD/MM/YYYY').toDate())
								: ''
						}
					/>
					<Section
						title="Handover Doc"
						content={
							<a href={data.handover_doc}>{data.handover_doc ? 'Link' : ''}</a>
						}
					/>
					<Section
						title="Status"
						content={
							<a href={data.handover_doc}>{data.handover_doc ? 'Link' : ''}</a>
						}
					/>
					<Section title="Created By" content={data.uploaded_by} />
					<Section
						title="Date Created"
						content={dateFormat.format(new Date(data.createdAt))}
					/>
				</TwoColumnDetails>
			)}
			<ImageGrid
				imageData={{
					tag_photo: data.tag_photo,
					handover_certificate_photo: data.handover_certificate_photo,
					pick_list_photo: data.pick_list_photo,
					scaffold_photo_1: data.scaffold_photo_1,
					scaffold_photo_2: data.scaffold_photo_2,
					scaffold_photo_3: data.scaffold_photo_3,
				}}
			/>
			<AppFilesComponents.FileList type="scaffoldInspection" id={id || ''} />
		</div>
	)
}
