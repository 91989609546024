import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Column } from 'primereact/column'
import { PencilIcon } from '@heroicons/react/24/solid'
import { DataTable, DataTableExportFunctionEvent } from 'primereact/datatable'

import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { AppRoutes } from 'config'
import { DataTableServices, ScaffoldRegisterServices } from 'services'
import { ScaffoldRegisterFrom } from 'components/ScaffoldRegister'
import { FilterMatchMode } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { dateFormat } from 'utilities'

export function ScaffoldRegisterMainTable() {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } =
		ScaffoldRegisterServices.useScaffoldRegister()
	const [openTagForm, setOpenTagForm] = useState(false)
	console.log(openTagForm)

	const exportColumnPreprocess = (data: DataTableExportFunctionEvent<any>) => {
		switch (data.field) {
			case 'erect_date':
			case 'inspection_date':
			case 'next_inspection_date':
			case 'dismantle_date': {
				const date = data?.data as unknown as Date
				return dateFormat.format(date)
			}
			default:
				return data.data
		}
	}

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		FilterColumn,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'tag_no',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [],
			},
			{
				filterName: 'erect_date',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'inspection_date',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'next_inspection_date',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'dismantle_date',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'status',
				filterInitialValue: 'Active',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [],
			},
		],
		additionalGlobalFilterFields: ['area', 'sub_area', 'job_number', 'work_order_number', 'description', 'uploaded_by'],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	return (
		<div className="card">
			<PageHeading title="Scaffold Register" setOpen={setOpenTagForm} />
			<Container>
				<DataTable
					ref={dataTableReference}
					value={data}
					paginator
					showGridlines
					rows={100}
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					exportFilename="Crockett Scaffold Register"
					exportFunction={(currentFilterState) =>
						exportColumnPreprocess(currentFilterState)
					}
					emptyMessage="No Scaffold Register found.">
					<Column
						header="Workfront (Details)"
						field="tag_no"
						filter
						showFilterOperator={false}
						filterPlaceholder="Search by tag Number or Name"
						style={{ minWidth: '10rem' }}
						body={(row) => (
							<Link
								to={AppRoutes.privateRoutes.scaffoldRegisterDetail.replace(
									':id',
									row.id || '',
								)}>
								{row.tag_no}
							</Link>
						)}
					/>
					<Column
						header="Area"
						field="area"
						style={{ minWidth: '6rem' }}
						{...FilterColumn.job_number}
					/>
					<Column
						header="Sub Area"
						field="sub_area"
						style={{ minWidth: '6rem' }}
						{...FilterColumn.job_number}
					/>
					<Column
						header="K2 Number"
						field="job_number"
						style={{ minWidth: '6rem' }}
						{...FilterColumn.job_number}
					/>
					<Column
						header="Work Order Number"
						field="work_order_number"
						style={{ minWidth: '6rem' }}
						{...FilterColumn.work_order_number}
					/>
					<Column
						header="Description"
						field="description"
						style={{ minWidth: '6rem' }}
						{...FilterColumn.description}
					/>
					<Column
						header="Date Of Erection"
						field="erect_date"
						body={(row) =>
							row.erect_date ? dateFormat.format(row.erect_date) : null
						}
						style={{ minWidth: '10rem' }}
						{...FilterColumn.erect_date}
					/>
					<Column
						header="Inspection Date"
						field="inspection_date"
						body={(row) =>
							row.inspection_date
								? dateFormat.format(row.inspection_date)
								: null
						}
						style={{ minWidth: '10rem' }}
						{...FilterColumn.inspection_date}
					/>
					<Column
						header="Next Inspection Date"
						field="next_inspection_date"
						body={(row) =>
							row.next_inspection_date
								? dateFormat.format(row.next_inspection_date)
								: null
						}
						style={{ minWidth: '10rem' }}
						{...FilterColumn.next_inspection_date}
					/>
					<Column
						header="Dismantle Date"
						field="dismantle_date"
						body={(row) =>
							row.dismantle_date ? dateFormat.format(row.dismantle_date) : null
						}
						style={{ minWidth: '10rem' }}
						{...FilterColumn.dismantle_date}
					/>
					<Column
						header="Uploaded By"
						field="uploaded_by"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						header="Status"
						field="status"
						bodyClassName="p-text-center"
						style={{ width: '6rem' }}
						body={(row) => <Badge type={row.status} text={row.status} />}
						{...FilterColumn.status}
					/>
					{enableCreateUpdate && (
						<Column
							header="Edit"
							bodyClassName="p-text-center"
							style={{ width: '3rem' }}
							body={(row) => (
								<Link
									to={{
										pathname:
											AppRoutes.privateRoutes.scaffoldRegisterEdit.replace(
												':id',
												row.id || '',
											),
									}}
									state={{
										background: location,
										name: 'editScaffoldRegister',
									}}>
									<PencilIcon className="text-gray-600 h-4 w-4" />
								</Link>
							)}
						/>
					)}
				</DataTable>
			</Container>
			<ScaffoldRegisterFrom
				formType="create"
				heading="Create Scaffold Register"
				open={openTagForm}
				setOpen={setOpenTagForm}
			/>
		</div>
	)
}
