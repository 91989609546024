import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import { AppStore } from 'redux/store'
import { Roles } from 'models'
import moment from 'moment'

export const useGetTagByJobId = (id?: string | number) => {
	const userState = useSelector((store: AppStore) => store.user)
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const enableCreateUpdate = userState.userType === Roles.admin

	const fetchTagsByJobId = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverScaffoldRegisterRoutes.getAllScaffoldRegisterByJobId,
				id,
			)

			const newData = response.data.map(
				(tag: {
					last_inspection: string
					inspection_date: string
					next_inspection_date: string
					dismantle_date: string
					erect_date: string
				}) => {
					return {
						...tag,
						last_inspection_date: tag.last_inspection
							? moment(tag.last_inspection, 'DD/MM/YYYY').toDate()
							: null,
						inspection_date: tag.inspection_date
							? moment(tag.inspection_date, 'DD/MM/YYYY').toDate()
							: null,
						next_inspection_date: tag.next_inspection_date
							? moment(tag.next_inspection_date, 'DD/MM/YYYY').toDate()
							: null,
						erect_date: tag.erect_date
							? moment(tag.erect_date, 'DD/MM/YYYY').toDate()
							: null,
						dismantle_date: tag.dismantle_date
							? moment(tag.dismantle_date, 'DD/MM/YYYY').toDate()
							: null,
					}
				},
			)

			newData.sort(
				(a: any, b: any) =>
					b?.inspection_date?.getTime() - a?.inspection_date?.getTime(),
			)

			return newData
		} catch (error) {
			showError('Something went wrong getting the scaffold register')
			throw new Error('Something went wrong getting the scaffold register')
		}
	}

	if (!id) {
		return {
			data: undefined,
			isLoading: false,
			error: undefined,
			enableCreateUpdate,
		}
	}

	const { data, isLoading, error } = useQuery(
		['tag', id || ''],
		fetchTagsByJobId,
	)

	return { data, isLoading, error, enableCreateUpdate }
}
