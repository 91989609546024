import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useDeleteNewsFeedMessage = () => {
	const { showError, showSuccess } = useContext(NotificationsContext)
	const { deleteRequest } = useApi()
	const queryClient = useQueryClient()

	const deleteNewsFeedMessage = async (newsfeedmessage: number) => {
		try {
			const response = await deleteRequest(
				AppRoutes.serverNewsFeedMessageRoutes.deleteNewsFeedMessage,
				newsfeedmessage,
			)
			queryClient.refetchQueries(['newsfeedmessage'])
			queryClient.refetchQueries(['newsfeedmessage_newsfeed'])
			return response.data
		} catch (error) {
			showError('Something went wrong when removing the news feed message')
			throw new Error('Something went wrong when removing the news feed message')
		}
	}

	return { deleteNewsFeedMessage }
}
