import { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { DayTimesheetServices, DataTableServices } from 'services'
import { Container, DataTableHeader, PageHeading, Spinner } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'

export const ApprovedDayTimesheetsMainTable = () => {
	const [dayTimesheetsSelected, setDayTimesheetsSelected] = useState(null)
	const { data: dayTimesheetData, isLoading: dayTimesheetDataIsLoading } =
		DayTimesheetServices.useDayTimesheets()

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: [],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})
	if (dayTimesheetDataIsLoading) {
		return <Spinner />
	}
	const filteredDayTimesheets = dayTimesheetData.filter(
		(dayTimesheet: any) => dayTimesheet.approved_by != '',
	)

	return (
		<div className="card">
			<PageHeading title="Approved DayTimesheets" />
			<Container>
				<DataTable
					ref={dataTableReference}
					value={filteredDayTimesheets}
					loading={dayTimesheetDataIsLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowGroupMode="subheader"
					groupRowsBy="sort_date"
					sortField='sort_date'
					sortOrder={-1}
					rowGroupHeaderTemplate={headerTemplate}
					// rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					// filters={filters}
					// globalFilterFields={globalFilterFields}
					header={header}
					exportFilename={'Crockett Approved Day Timesheets'}
					selection={dayTimesheetsSelected}
					onSelectionChange={(e) => setDayTimesheetsSelected(e.value as never)}
					emptyMessage="No Approved Day Timesheets found.">
					<Column
						field="date"
						header="Date"
						filterField="filter_date"
						body={(row) => {
							const date = row.date
								? moment(row?.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
								: ''
							return date
						}}
						dataType="date"
						style={{ minWidth: '10rem' }}
						filter
						filterType="date"
					/>
					<Column
						body={(row) => {
							return row?.staffData?.staff_name
						}}
						field="staffData.staff_name"
						header="Staff"
					/>
					<Column
						field="jobData.clientData.client_name"
						header="Workfront"
						style={{ minWidth: '12rem' }}
						body={(row) => {
							// Get client name from job number (workfront)
							return row?.jobData?.clientData?.client_name
						}}
					/>
					<Column
						header="Sub Area"
						field="subArea"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						field="comments"
						header="Comments"
						style={{ minWidth: '12rem' }}
					/>
					<Column
						field="time_on"
						header="Time On"
						style={{ minWidth: '12rem' }}
					/>
					<Column
						header="Time Off"
						field="time_off"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						header="Approved By"
						field="approved_by"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						field="exported"
						header="Exported"
						bodyClassName="p-text-center"
						style={{ minWidth: '2rem' }}
					/>
				</DataTable>
			</Container>
		</div>
	)
}

// const calculateDayTimesheetTotal = (data: any) => {
// 	// let total = 0
// 	console.log('data: ', data)
// 	// if (data.staffData) {
// 	// 	for (const dayTimesheet of filteredTime.data) {
// 	// 		if (Number(dayTimesheet.staff_id) === data.staffData.staff_id) {
// 	// 			// console.log("MATCHING ID", data.staff_id)
// 	// 			// const hours = dayTimesheet.time_off
// 	// 			//   ? Number(calcHours(dayTimesheet.time_on, dayTimesheet.time_off))
// 	// 			//   : 0;
// 	// 			const hours = dayTimesheet.time_off ? Number(dayTimesheet.hours) : 0
// 	// 			total += hours
// 	// 		}
// 	// 	}
// 	// }
// 	// return total
// }

const headerTemplate = (data: {
	date: string,
	staffData: {
		staff_name: string
	}
}) => (
	<span className="text-gray-900 font-bold">{data.date
		? moment(data?.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : '' }</span>
)

// const footerTemplate = (data: any) => (
// 	<>
// 		<td
// 			colSpan={7}
// 			style={{ textAlign: 'right' }}
// 			className="bg-gray-100 font-normal">
// 			Total Hours
// 		</td>
// 		{calculateDayTimesheetTotal(data)}
// 	</>
// )
