import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useNewsFeeds = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchNewsFeed = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverNewsFeedRoutes.getAllNewsFeed,
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting News Feed')
			throw new Error('Something went wrong getting News Feed')
		}
	}

	const { data, isLoading, error } = useQuery(['newsfeed'], fetchNewsFeed)

	return { data, isLoading, error }
}

export const useNewsFeedsById = (newsfeed_id?: string | number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchNewsFeedById = async () => {
		try {
			if (!newsfeed_id) return null
			const response = await getRequest(
				AppRoutes.serverNewsFeedRoutes.getNewsFeed,
				newsfeed_id,
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting News Feed')
			throw new Error('Something went wrong getting News Feed')
		}
	}

	const { data, isLoading, error } = useQuery(['newsfeed', newsfeed_id], fetchNewsFeedById)

	return { data, isLoading, error }
}

export const useNewsFeedsByStaffID = (id: number | string) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAllNewsFeedsByStaffId = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverNewsFeedRoutes.getAllNewsFeedByStaffId,
				id,
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting the News Feeds')
			throw new Error('Something went wrong getting the News Feeds')
		}
	}

	const { data, isLoading, error } = useQuery(['newsfeed_staff'], fetchAllNewsFeedsByStaffId)

	return { data, isLoading, error }
}
