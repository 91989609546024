import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useNewsFeedMessages = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchNewsFeedMessage = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverNewsFeedMessageRoutes.getAllNewsFeedMessage,
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting news feed message')
			throw new Error('Something went wrong getting news feed message')
		}
	}

	const { data, isLoading, error } = useQuery(['newsfeedmessage'], fetchNewsFeedMessage)

	return { data, isLoading, error }
}

export const useNewsFeedMessagesById = (newsfeedmessage_id?: string | number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchNewsFeedMessageById = async () => {
		try {
			if (!newsfeedmessage_id) return null
			const response = await getRequest(
				AppRoutes.serverNewsFeedMessageRoutes.getAllNewsFeedMessage,
				newsfeedmessage_id,
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting news feed message')
			throw new Error('Something went wrong getting news feed message')
		}
	}

	const { data, isLoading, error } = useQuery(['newsfeedmessage', newsfeedmessage_id], fetchNewsFeedMessageById)

	return { data, isLoading, error }
}

export const useNewsFeedMessagesByNewsFeedID = (id: number | string) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAllNewsFeedMessagesByNewsFeedId = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverNewsFeedMessageRoutes.getAllNewsFeedMessageByNewsFeedId,
				id,
			)
			const messagesOrderByIDDesc = response?.data.sort((a: any, b: any) => a.id - b.id)
			return messagesOrderByIDDesc
		} catch (error) {
			showError('Something went wrong getting the news feed messages')
			throw new Error('Something went wrong getting the news feed messages')
		}
	}

	const { data, isLoading, error } = useQuery(['newsfeedmessage_newsfeed'], fetchAllNewsFeedMessagesByNewsFeedId)

	return { data, isLoading, error }
}

export const noQuery_useNewsFeedMessagesByNewsFeedID = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAllNewsFeedMessagesByNewsFeedId = async (id: number | string) => {
		try {
			const response = await getRequest(
				AppRoutes.serverNewsFeedMessageRoutes.getAllNewsFeedMessageByNewsFeedId,
				id,
			)
			const messagesOrderByIDDesc = response?.data.sort((a: any, b: any) => a.id - b.id)
			return messagesOrderByIDDesc
		} catch (error) {
			showError('Something went wrong getting the news feed messages')
			throw new Error('Something went wrong getting the news feed messages')
		}
	}

	

	return { fetchAllNewsFeedMessagesByNewsFeedId }
}
