import { CheckIcon } from '@heroicons/react/24/solid'
import { Button, ConfirmationDialog } from 'common'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { TimesheetServices } from 'services'

interface ApproveTimesheetProps {
	timesheets: any
}

export const ApproveTimesheet = ({ timesheets }: ApproveTimesheetProps) => {
	const { updateTimesheet } = TimesheetServices.useUpdateTimesheetById()
	const userState = useSelector((store: AppStore) => store.user)
	const handleApproveTimesheets = async () => {
		console.log(timesheets)
		const selectedTimesheets = timesheets.map((row: any) => ({
			id: row.id,
			date: row.date,
			trade: row.trade,
			totalPrestartHours: row.totalPrestartHours,
			prestartHours: row.prestartHours,
			job_id: row.job_id,
			k2: row.k2,
			workOrderNumber: row.workOrderNumber,
			lunch: row.lunch,
			dayOrNightShift: row.dayOrNightShift,
			time_on: row.time_on,
			time_off: row.time_off,
			workCompletedToday: row.workCompletedToday,
			hours: row.hours,
			totalHours: row.totalHours,
			comments: row.comments,
			status: 'Approved',
			staff_id: row.staff_id,
			timesheet_id: row.timesheet_id,
			approved_by: userState.name,
			actual_finish: row.actual_finish,
			exported: 'No',
			visitTimeSheetId: row.visitTimeSheetId,
		}))
		console.log(selectedTimesheets)
		for (const timesheet of selectedTimesheets) {
			await updateTimesheet(timesheet.id, timesheet)
		}
	}

	return (
		<ConfirmationDialog
			icon="info"
			title={'Approve Timesheet'}
			body="Are you sure you wish to approve this timesheet?"
			triggerButton={
				<Button
					variant="approve"
					size="sm"
					startIcon={
						<CheckIcon
							className="-ml-0.5 mr-2 h-4 w-4 text-white"
							aria-hidden="true"
						/>
					}>
					Approve Timesheets
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					variant="approve"
					onClick={async () => handleApproveTimesheets()}>
					Approve
				</Button>
			}
		/>
	)
}
