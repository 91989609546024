import { Section, Spinner, Tabs, TwoColumnDetails } from 'common'
import { WeeklyHireTable } from 'components/Invoices'
import { EDInvoicesTable } from 'components/Invoices/EdInvoices/EdInvoicesTable'
import {
	JobTaskTable,
	HandoverFrom,
	JobVariationsTableTable,
} from 'components/Jobs'
import { Notes } from 'components/Notes'
import { ScaffoldRegisterTable } from 'components/ScaffoldRegister'
import { VisitsTable, VisitsTimesheetsTable } from 'components/Visits'
import { AppRoutes } from 'config'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { FencingJobServices } from 'services'

const items = [
	{ label: 'Tasks', id: 1 },
	{ label: 'Fencing Files', id: 2 },
	// { label: 'Visit Timesheets', id: 3 },
	// { label: 'Scaffold Tags', id: 4 },
	// { label: 'Weekly Hire Invoices', id: 5 },
	// { label: 'E&D Invoices', id: 6 },
	// { label: 'K2 Files', id: 7 },
	// { label: 'Quoted hours vs. Actual hours', id: 8 },
]

export const JobDetails = () => {
	const { id } = useParams()
	const location = useLocation()
	const jobId = parseInt(id || '') || undefined
	const [tabIndex, setTabIndex] = useState(1)
	const {
		data: deliveryJob,
		isLoading,
		enableCreateUpdate,
	} = FencingJobServices.useFencingJobById(jobId)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [handover, setHandover] = useState<any>({})
	const { data, isLoading: handoverLoading } =
		FencingJobServices.useHandoverByJobId(jobId)
	useEffect(() => {
		if (!data) return
		setHandover(data)
	}, [data])

	if (isLoading || handoverLoading) {
		return <Spinner />
	}

	return (
		<>
			<div className="w-full mx-auto mt-8 mb-28">
				<TwoColumnDetails
					heading="Fencing Job Details"
					editBtn="Edit Fencing Job"
					editLink={{
						to: AppRoutes.privateRoutes.K2Edit.replace(':id', id || ''),
						state: {
							background: location,
							name: 'editJob',
						},
					}}
					isEditable={enableCreateUpdate}>
					<Section title="Fencing Job #" content={deliveryJob.job_num} />
					<Section title="Fencing Job Type" content={deliveryJob.job_type} />
					<Section title="Client" content={deliveryJob.client_name} />
					<Section title="Site" content={deliveryJob.site} />
					<Section
						title="Delivery Date"
						content={
							deliveryJob.start_date
								? moment(deliveryJob.start_date).format('DD/MM/YYYY')
								: 'N/A'
						}
					/>
					<Section
						title="Return Date"
						content={
							deliveryJob.end_date
								? moment(deliveryJob.end_date).format('DD/MM/YYYY')
								: 'N/A'
						}
					/>
					<Section
						title="Fencing Job Status"
						content={deliveryJob.job_status}
					/>
					<Section title="Status" content={deliveryJob.status} />

					<Section
						title="Requirements"
						content={deliveryJob.descriptionOfQuote}
					/>

					<Section title="Notes" content={deliveryJob.notes} />
				</TwoColumnDetails>{' '}
				<div className="px-8">
					<Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={items} />
				</div>
				{tabIndex === 1 && (
					<>
						<JobTaskTable is_delivery job_id={jobId} />
						{/* <JobVariationsTableTable job_id={jobId} client_id={k2.client_id} /> */}
					</>
				)}
				{tabIndex === 2 && <Notes type="fencing" id={id || ''} />}
			</div>
		</>
	)
}
