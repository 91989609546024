import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useCreateNewsFeedMessage = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest, getRequest } = useApi()
	const queryClient = useQueryClient()

	const createNewsFeedMessage = async (newsfeedmessage: unknown) => {
		try {
			const response = await putRequest(
				AppRoutes.serverNewsFeedMessageRoutes.createNewsFeedMessage,
				newsfeedmessage,
			)
			queryClient.refetchQueries(['newsfeedmessage'])
			queryClient.refetchQueries(['newsfeedmessage_newsfeed'])
			showSuccess('News feed messages created successfully')
			return response
		} catch (error) {
			showError('Something went wrong creating News feed messages File')
			throw new Error((error as Error).message)
		}
	}
	return { createNewsFeedMessage }
}