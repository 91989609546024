import { AppFilesServices } from 'services'
import { Table } from 'common'
import moment from 'moment'

export const MainFilesTable = () => {
	const { data, isLoading } = AppFilesServices.useAppFiles()

	const cols = [
		{
			header: 'Date Added',
			field: 'date',
			body: (row: { date: string }) => {
				return row.date
					? moment(row.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
					: ''
			},
		},
		{
			header: 'File Type',
			field: 'file_type',
		},
		{
			header: 'Description',
			field: 'file_description',
		},
		{
			header: 'File',
			field: 'file',
			body: (rowData: { link: string }) => (
				<a href={rowData.link} target="_blank" rel="noreferrer">
					Link
				</a>
			),
		},
		{
			header: 'Uploaded By',
			field: 'uploaded_by',
		},
	]

	return (
		<>
			<Table
				columns={cols}
				data={data}
				isLoading={isLoading}
				title="App Files"
				sortField={'date'}
				sortOrder={-1}
			/>
		</>
	)
}
