import { CheckIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Button, ConfirmationDialog } from 'common'
import { AppStore } from 'redux/store'
import { useSelector } from 'react-redux'
import { useApproveLeave } from 'services/leave/ApproveLeave'
interface Props {
	leaveId: number
	status: string
}

export const ApproveLeave = ({ leaveId, status }: Props) => {
	const { approveLeave: approveLeaveFn } = useApproveLeave()
	const userState = useSelector((store: AppStore) => store.user)
	const [isLoading, setIsLoading] = useState(false)

	return (
		<ConfirmationDialog
			icon="info"
			title="Approve Leave"
			body=" Are you sure you want to approve this leave? This action will create a job with a list of tasks."
			triggerButton={
				<button
					type="button"
					id={`${leaveId}`}
					className={
						status !== 'Approved' && status !== 'Declined'
							? 'ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400'
							: 'ml-3 inline-flex items-center text-sm text-gray-200'
					}
					disabled={status === 'Approved' || status === 'Declined'}>
					<CheckIcon
						className={
							status !== 'Approved' &&
							status !== 'Rejected' &&
							status !== 'Declined'
								? '-ml-0.5 mr-2 h-4 w-4 text-green-400'
								: '-ml-0.5 mr-2 h-4 w-4 text-green-100'
						}
						aria-hidden="true"
					/>
					Approve
				</button>
			}
			confirmButton={
				<Button
					isLoading={isLoading}
					size="sm"
					variant="approve"
					onClick={async () => {
						setIsLoading(true)
						approveLeaveFn(leaveId, userState.name)
						setIsLoading(false)
					}}>
					Approve
				</Button>
			}
		/>
	)
}
