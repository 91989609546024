import { useMemo, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { TimesheetServices, DataTableServices, K2Services } from 'services'
import { Container, DataTableHeader, PageHeading, Spinner } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'
import { ApproveTimesheet } from 'components/Timesheets'
import { FilterMatchMode } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'

export const TimesheetsMainTable = () => {
	const [timesheetsSelected, setTimesheetsSelected] = useState(null)
	const { data: timesheetData, isLoading: timesheetDataIsLoading } =
		TimesheetServices.useTimesheets()
	const { data: jobData, isLoading: jobDataIsLoading } = K2Services.useJobs()
	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'filter_date',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
		],
		additionalGlobalFilterFields: [
			'staffData.staff_name',
			'date',
			'workCompletedToday',
			'workOrderNumber',
			'trade',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	const filterTimesheets = (data: any[]) => {
		if (data) {
			return data.filter((timesheet: any) => timesheet.status !== 'Approved')
		} else {
			return []
		}
	}

	const filteredTimesheets = useMemo(
		() => filterTimesheets(timesheetData),
		[timesheetData],
	)

	const getClientNameFromWorkfront = (workfront: string) => {
		const job = jobData.find((job: any) => job.job_num === workfront)
		return job?.clientData?.client_name
	}

	if (timesheetDataIsLoading || jobDataIsLoading) {
		return <Spinner />
	}

	return (
		<div className="card">
			<PageHeading title="Timesheets" />
			<Container>
				<div className="flex justify-between ml-4">
					<ApproveTimesheet timesheets={timesheetsSelected} />
				</div>
				<DataTable
					ref={dataTableReference}
					value={filteredTimesheets}
					loading={timesheetDataIsLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowGroupMode="subheader"
					groupRowsBy="date"
					rowGroupHeaderTemplate={headerTemplate}
					// rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					sortField='date'
					sortOrder={-1}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					exportFilename={'Crockett Timesheets'}
					selection={timesheetsSelected}
					onSelectionChange={(e) => setTimesheetsSelected(e.value as any)}
					emptyMessage="No Timesheets found.">
					<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<Column
						body={(row) => {
							return row?.staffData?.staff_name
						}}
						header="Staff"
					/>
					<Column
						header="Date"
						filterField="filter_date"
						body={(row) => {
							const date = row.date
								? moment(row?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
								: ''
							return date
						}}
						dataType="date"
						style={{ minWidth: '10rem' }}
						filter
						filterType="date"
						{...FilterColumn.filter_date}
					/>
					<Column
						header="Trade"
						field="trade"
						filterField="trade"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						field="workCompletedToday"
						header="Work Completed today"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
					/>
					<Column
						field="workOrderNumber"
						header="Work Order Number"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
					/>
					<Column
						field="workfront"
						header="Workfront"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
						body={(row) => {
							// Get client name from job number (workfront)
							return getClientNameFromWorkfront(String(row.workfront))
						}}
					/>
					<Column
						header="Prestart Start"
						field="prestartHours"
						filterField="prestartHours"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						header="Lunch Break"
						body={(row) => {
							return row.lunch
						}}
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }}
					/>
					<Column
						field="hours"
						header="Hours"
						bodyClassName="p-text-center"
						style={{ minWidth: '8rem' }}
					/>
					{/* <Column
						header="Status"
						bodyClassName="p-text-center"
						style={{ width: '4rem' }}
						body={(row) => <Badge type={row.status} text={row.status} />}
					/>
					<Column field="" body="Edit" /> */}
				</DataTable>
			</Container>
		</div>
	)
}

const headerTemplate = (data: {
	date: string,
	staffData: {
		staff_name: string
	}
}) => (
	<span className="text-gray-900 font-bold">{data.date
		? moment(data?.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '' }</span>
)

// const footerTemplate = () => (
// 	<>
// 		<td
// 			colSpan={7}
// 			style={{ textAlign: 'right' }}
// 			className="bg-gray-100 font-normal">
// 			Total Hours
// 		</td>
// 	</>
// )
