import { DateSelect, Dropdown, Input, SideModal, Spinner } from 'common'
import { useFormik } from 'formik'
import { active_inactive_options } from 'models'
import moment from 'moment'
import { ScaffoldRegisterServices } from 'services'
import * as Yup from 'yup'
import { NotificationsContext } from 'context/notifications/toastContext'
import { useContext } from 'react'

interface IProps {
	tag_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	tag_no: string
	job_id: string
	description: string
	area: string
	sub_area: string
	last_inspection: string
	inspection_due: string
	status: string
	work_order_number: string
	job_number: string
	dismantle_date: string
}

export const ScaffoldRegisterFrom = ({
	tag_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { createScaffoldRegister } =
		ScaffoldRegisterServices.useCreateScaffoldRegister()
	const { updateScaffoldRegister } =
		ScaffoldRegisterServices.useUpdateScaffoldRegister()
	const { data: tagData, isLoading: tagLoading } =
		ScaffoldRegisterServices.useGetTagById(tag_id)
	const { showInfo } = useContext(NotificationsContext)
	// const { data: jobData, isLoading: jobsLoading } = JobsServices.useJobs()

	const initialValues: IInitialValues = {
		tag_no: tagData?.tag_no || '',
		job_id: tagData?.job_id || '',
		description: tagData?.description || '',
		area: tagData?.area || '',
		sub_area: tagData?.sub_area || '',
		last_inspection: tagData?.last_inspection
			? moment(tagData?.last_inspection).format('DD/MM/YYYY')
			: moment().format('DD/MM/YYYY'),
		inspection_due: tagData?.inspection_due
			? moment(tagData?.inspection_due).format('DD/MM/YYYY')
			: moment().format('DD/MM/YYYY'),
		status: tagData?.status || 'Active',
		work_order_number: tagData?.work_order_number,
		job_number: tagData?.job_number,
		dismantle_date: tagData?.dismantle_date,
	}

	const validationSchema = Yup.object({})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				const payload = {
					tag_no: values.tag_no,
					job_id: Number(values.job_id),
					description: values.description,
					area: values.area,
					sub_area: values.sub_area,
					last_inspection: moment(
						values.last_inspection,
						'DD/MM/YYYY',
					).toDate(),
					inspection_due: moment(values.inspection_due, 'DD/MM/YYYY').toDate(),
					dismantle_date: values.dismantle_date,
					job_number: values.job_number,
					work_order_number: values.work_order_number,
					status: values.status,
				}
				await createScaffoldRegister(payload)
			}
			if (formType === 'update' && tag_id) {
				const payload = {
					tag_no: values.tag_no,
					job_id: Number(values.job_id),
					description: values.description,
					area: values.area,
					sub_area: values.sub_area,
					last_inspection: moment(
						values.last_inspection,
						'DD/MM/YYYY',
					).toDate(),
					inspection_due: moment(values.inspection_due, 'DD/MM/YYYY').toDate(),
					dismantle_date: values.dismantle_date,
					job_number: values.job_number,
					work_order_number: values.work_order_number,
					status: values.status,
				}
				if (values.dismantle_date?.length > 1) {
					payload.status = 'Inactive'
					showInfo("Tag's status has been changed to Inactive.")
				}
				await updateScaffoldRegister(tag_id, payload)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (tag_id && tagLoading) {
		//  || jobsLoading
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center">
					<div className="w-1/2">
						<Input
							id="tag_no"
							type="text"
							value={formik.values.tag_no}
							error={formik.errors.tag_no}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Tag No"
							title="Tag No"
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="job_number"
							type="text"
							value={formik.values.job_number}
							error={formik.errors.job_number}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="K2 Number"
							title="K2 Number"
						/>
					</div>
				</div>
				<div className="flex items-center">
					<div className="w-1/2">
						<Input
							id="description"
							type="text"
							value={formik.values.description}
							error={formik.errors.description}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Description"
							title="Description"
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="work_order_number"
							type="text"
							value={formik.values.work_order_number}
							error={formik.errors.work_order_number}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Work Order Number"
							title="Work Order Number"
						/>
					</div>
				</div>
				<div className="flex items-center">
					<div className="w-1/2">
						<Input
							id="area"
							type="text"
							value={formik.values.area}
							error={formik.errors.area}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Area"
							title="Area"
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="sub_area"
							type="text"
							value={formik.values.sub_area}
							error={formik.errors.sub_area}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Sub Area"
							title="Sub Area"
						/>
					</div>
				</div>
				<div className="flex items-center">
					<div className="w-1/2">
						<DateSelect
							id="last_inspection"
							title="Last Inspection"
							value={formik.values.last_inspection}
							error={formik.errors.last_inspection}
							onChange={formik.setFieldValue}
						/>
					</div>
					<div className="w-1/2">
						<DateSelect
							id="inspection_due"
							title="Inspection Due"
							value={formik.values.inspection_due}
							error={formik.errors.inspection_due}
							onChange={formik.setFieldValue}
						/>
					</div>
				</div>
				<div className="flex items-center">
					<div className="w-1/2">
						<DateSelect
							id="dismantle_date"
							title="Dismantle Date"
							value={formik.values.dismantle_date}
							error={formik.errors.dismantle_date}
							onChange={formik.setFieldValue}
						/>
					</div>
				</div>
				<div className="flex items-center">
					<div className="w-full">
						<Dropdown
							id="status"
							label="Status"
							onChange={formik.setFieldValue}
							options={active_inactive_options}
							value={formik.values.status}
							onBlur={formik.setFieldTouched}
							error={formik.errors.status}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
