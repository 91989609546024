import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useDeleteNewsFeed = () => {
	const { showError, showSuccess } = useContext(NotificationsContext)
	const { deleteRequest } = useApi()
	const queryClient = useQueryClient()

	const deleteNewsFeed = async (newsfeed: number) => {
		try {
			const response = await deleteRequest(
				AppRoutes.serverNewsFeedRoutes.deleteNewsFeed,
				newsfeed,
			)
			showSuccess('News Feed removed successfully')
			queryClient.refetchQueries(['newsfeed'])
			queryClient.refetchQueries(['newsfeed_staff'])
			return response.data
		} catch (error) {
			showError('Something went wrong when removing the note')
			throw new Error('Something went wrong when removing the note')
		}
	}

	return { deleteNewsFeed }
}
