import { Table } from 'common'
import { jobFileColumns } from './Columns'
import { useNotesJobs } from 'services/notes'

interface JobsTableProps {
	id: string | number
}

export const MobileScaffoldJobsTable = ({ id }: JobsTableProps) => {
	const { data, isLoading } = useNotesJobs(id)

	return (
		<div>
			<Table
				columns={jobFileColumns}
				data={data}
				isLoading={isLoading}
				title="Mobile Scaffold Notes & Files"
			/>
		</div>
	)
}
