import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useDeclineLeave = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const declineLeave = async (leaveId: number, approvedBy: string) => {
		try {
			const response = await postRequest(
				AppRoutes.serverLeavesRoutes.declineLeave,
				{
					leaveId,
					status: 'Declined',
					approvedBy,
				},
				leaveId
			)
			queryClient.refetchQueries(['leaves'])
			queryClient.refetchQueries(['approvedLeaves'])
			queryClient.refetchQueries(['leavesStaff'])
			showSuccess('Leave updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating Leave')
			throw new Error((error as Error).message)
		}
	}
	return { declineLeave }
}
