import moment from 'moment'

export const columns = [
	{
		header: 'Created Date',
		field: 'date_added',
		body: (row: { date: string }) => {
			return row.date ? moment(row.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
		},
	},
	{
		header: 'File Type',
		field: 'file_type',
	},
	{
		header: 'File',
		field: 'file',
		body: (rowData: { link: string }) => <a href={rowData.link}>Link</a>,
	},
	{
		header: 'Uploaded By',
		field: 'uploaded_by',
	},
]

export const vehicleFileColumns = [
	{
		header: 'Created Date',
		field: 'date',
		body: (row: { date: Date }) => {
			return row.date ? moment(row.date).format('DD/MM/YYYY') : ''
		},
	},
	{
		header: 'File Type',
		field: 'file_type',
	},
	{
		header: 'File Description',
		field: 'file_name',
	},
	{
		header: 'Notes',
		field: 'notes',
	},
	{
		header: 'File',
		field: 'file',
		body: (rowData: { link: string }) => <a href={rowData.link}>Link</a>,
	},
]
