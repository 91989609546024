import { Badge, Table } from 'common'
import { ScaffoldRegisterServices } from 'services'
import { dateFormat } from 'utilities'

interface ScaffoldRegisterTableProps {
	job_id?: number
}

export const ScaffoldRegisterTable = ({
	job_id,
}: ScaffoldRegisterTableProps) => {
	const { data, isLoading } = ScaffoldRegisterServices.useGetTagByJobId(job_id)
	const columns = [
		{ field: 'tag_no', header: 'Tag #' },
		{ field: 'description', header: 'Description' },
		{
			field: '',
			header: 'Inspection Date',
			body: (rowData: { inspection_date: Date }) => {
				return rowData.inspection_date
					? dateFormat.format(rowData.inspection_date)
					: ''
			},
		},
		{
			field: '',
			header: 'Date Of Erection',
			body: (rowData: { erect_date: Date }) => {
				return rowData.erect_date ? dateFormat.format(rowData.erect_date) : ''
			},
		},
		{
			field: '',
			header: 'Next Inspection Date',
			body: (rowData: { next_inspection_date: Date }) => {
				return rowData.next_inspection_date
					? dateFormat.format(rowData.next_inspection_date)
					: ''
			},
		},
		{
			field: '',
			header: 'Dismantle Date',
			body: (rowData: { dismantle_date: Date }) => {
				return rowData.dismantle_date
					? dateFormat.format(rowData.dismantle_date)
					: ''
			},
		},
		{
			field: 'uploaded_by',
			header: 'Uploaded By',
		},
		{
			field: '',
			header: 'Status',
			body: (rowData: { status: string }) => {
				return <Badge text={rowData.status} type={rowData.status} />
			},
		},
	]
	console.log(data)
	return (
		<>
			<Table
				columns={columns}
				data={data}
				isLoading={isLoading ?? false}
				title="Scaffold Register"
				disableButtons
			/>
		</>
	)
}
