import { useMemo, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { DayTimesheetServices, DataTableServices } from 'services'
import { Container, DataTableHeader, PageHeading, Spinner } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'
import { ApproveDayTimesheet } from 'components/DayTimesheets'
import { FilterMatchMode } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'

export const DayTimesheetsMainTable = () => {
	const [dayDayTimesheetsSelected, setDayTimesheetsSelected] = useState(null)
	const { data: dayTimesheetData, isLoading: dayTimesheetDataIsLoading } =
		DayTimesheetServices.useDayTimesheets()
	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'filter_date',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
		],
		additionalGlobalFilterFields: [
			'staffData.staff_name',
			'date',
			'workCompletedToday',
			'workOrderNumber',
			'trade',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	const filterDayTimesheets = (data: any[]) => {
		if (data) {
			return data.filter(
				(dayDayTimesheet: any) => dayDayTimesheet.approved_by === '',
			)
		} else {
			return []
		}
	}

	const filteredDayTimesheets = useMemo(
		() => filterDayTimesheets(dayTimesheetData),
		[dayTimesheetData],
	)

	if (dayTimesheetDataIsLoading) {
		return <Spinner />
	}

	// const exportColumnPreProcess = (data: DataTableExportFunctionEvent<any>) => {
	// 	console.log(data)
	// 	switch (data.field) {
	// 		case 'workfront': {
	// 			const row = data.rowData as any
	// 			console.log(row)
	// 			return row?.jobData?.clientData?.client_name
	// 		}
	// 		default:
	// 			return data.data
	// 	}
	// }

	console.log(filteredDayTimesheets[0])

	return (
		<div className="card">
			<PageHeading title="Day Timesheets" />
			<Container>
				<div className="flex justify-between ml-4">
					<ApproveDayTimesheet dayTimesheets={dayDayTimesheetsSelected} />
				</div>
				<DataTable
					ref={dataTableReference}
					value={filteredDayTimesheets}
					loading={dayTimesheetDataIsLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowGroupMode="subheader"
					groupRowsBy="sort_date"
					sortField='sort_date'
					sortOrder={-1}
					rowGroupHeaderTemplate={headerTemplate}
					// rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					exportFilename={'Crockett Day Timesheets'}
					selection={dayDayTimesheetsSelected}
					onSelectionChange={(e) => setDayTimesheetsSelected(e.value as any)}
					emptyMessage="No Day Timesheets found.">
					<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<Column
						field="date"
						header="Date"
						filterField="filter_date"
						body={(row) => {
							const date = row.date
								? moment(row?.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
								: ''
							return date
						}}
						dataType="date"
						style={{ minWidth: '10rem' }}
						filter
						filterType="date"
						{...FilterColumn.filter_date}
					/>
					<Column
						body={(row) => {
							return row?.staffData?.staff_name
						}}
						field="staffData.staff_name"
						header="Staff"
					/>
					<Column
						header="Workfront"
						style={{ minWidth: '12rem' }}
						field="jobData.clientData.client_name"
						body={(row) => {
							// Get client name from job number (workfront)
							return row?.jobData?.clientData?.client_name
						}}
					/>
					<Column
						header="Sub Area"
						field="subArea"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						field="comments"
						header="Comments"
						style={{ minWidth: '12rem' }}
					/>
					<Column
						field="time_on"
						header="Time On"
						style={{ minWidth: '12rem' }}
					/>
					<Column
						header="Time Off"
						field="time_off"
						style={{ minWidth: '10rem' }}
					/>
					{/* <Column
						header="Approved By"
						field="approved_by"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						field="exported"
						header="Exported"
						bodyClassName="p-text-center"
						style={{ minWidth: '2rem' }}
					/>
					 <Column
						header="Status"
						bodyClassName="p-text-center"
						style={{ width: '4rem' }}
						body={(row) => <Badge type={row.status} text={row.status} />}
					/>
					<Column field="" body="Edit" /> */}
				</DataTable>
			</Container>
		</div>
	)
}

const headerTemplate = (data: {
	date: string,
	staffData: {
		staff_name: string
	}
}) => (
	<span className="text-gray-900 font-bold">{data.date
		? moment(data?.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : '' }</span>
)

// const footerTemplate = () => (
// 	<>
// 		<td
// 			colSpan={7}
// 			style={{ textAlign: 'right' }}
// 			className="bg-gray-100 font-normal">
// 			Total Hours
// 		</td>
// 	</>
// )
