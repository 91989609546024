import { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { TimesheetServices, DataTableServices, K2Services } from 'services'
import { Badge, Container, DataTableHeader, PageHeading, Spinner } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'

export const ApprovedTimesheetsMainTable = () => {
	const [timesheetsSelected, setTimesheetsSelected] = useState(null)
	const { data: timesheetData, isLoading: timesheetDataIsLoading } =
		TimesheetServices.useTimesheets()
	const { data: jobData, isLoading: jobDataIsLoading } = K2Services.useJobs()

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: [],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})
	if (timesheetDataIsLoading || jobDataIsLoading) {
		return <Spinner />
	}
	const filteredTimesheets = timesheetData.filter(
		(timesheet: any) => timesheet.status === 'Approved',
	)

	const getClientNameFromWorkfront = (workfront: string) => {
		const job = jobData.find((job: any) => job.job_num === workfront)
		return job?.clientData?.client_name
	}

	return (
		<div className="card">
			<PageHeading title="Approved Timesheets" />
			<Container>
				<DataTable
					ref={dataTableReference}
					value={filteredTimesheets}
					loading={timesheetDataIsLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowGroupMode="subheader"
					groupRowsBy="date"
					sortField='date'
					sortOrder={-1}
					rowGroupHeaderTemplate={headerTemplate}
					// rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					// filters={filters}
					// globalFilterFields={globalFilterFields}
					header={header}
					exportFilename={'Crockett Approved Timesheets'}
					selection={timesheetsSelected}
					onSelectionChange={(e) => setTimesheetsSelected(e.value as never)}
					emptyMessage="No Timesheets found.">
					<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<Column
						style={{ minWidth: '5rem' }}
						body={(row) => {
							console.log(row)
							return row?.staffData?.staff_name || ''
						}}
						header="Staff"
					/>
					<Column
						header="Date"
						filterField="date"
						body={(row) => {
							const date = row.date
								? moment(row?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
								: ''
							return date
						}}
						dataType="date"
						style={{ minWidth: '10rem' }}
						filter
						filterType="date"
					/>
					<Column
						header="Trade"
						field="trade"
						filterField="trade"
						style={{ minWidth: '10rem' }}
					/>

					<Column
						field="workCompletedToday"
						header="Work Completed today"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
					/>
					<Column
						field="workOrderNumber"
						header="Work Order Number"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
					/>
					{/* <Column
						field="k2"
						header="K2#"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
					/> */}
					<Column
						field="workfront"
						header="Workfront"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
						body={(row) => {
							// Get client name from job number (workfront)
							return getClientNameFromWorkfront(String(row.workfront))
						}}
					/>
					<Column
						header="Prestart Start"
						field="prestartHours"
						filterField="prestartHours"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						header="Lunch Break"
						body={(row) => {
							return row.lunch
						}}
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }}
					/>
					<Column
						field="comments"
						header="Comments"
						bodyClassName="p-text-center"
						style={{ minWidth: '8rem' }}
					/>
					<Column
						header="Status"
						bodyClassName="p-text-center"
						style={{ width: '4rem' }}
						body={(row) => <Badge type={row.status} text={row.status} />}
					/>
					<Column
						field="approved_by"
						header="Approved By"
						bodyClassName="p-text-center"
						style={{ width: '8rem' }}
					/>
					<Column
						field="exported"
						header="Exported"
						bodyClassName="p-text-center"
						style={{ width: '4rem' }}
					/>
				</DataTable>
			</Container>
		</div>
	)
}

// const calculateTimesheetTotal = (data: any) => {
// 	// let total = 0
// 	console.log('data: ', data)
// 	// if (data.staffData) {
// 	// 	for (const timesheet of filteredTime.data) {
// 	// 		if (Number(timesheet.staff_id) === data.staffData.staff_id) {
// 	// 			// console.log("MATCHING ID", data.staff_id)
// 	// 			// const hours = timesheet.time_off
// 	// 			//   ? Number(calcHours(timesheet.time_on, timesheet.time_off))
// 	// 			//   : 0;
// 	// 			const hours = timesheet.time_off ? Number(timesheet.hours) : 0
// 	// 			total += hours
// 	// 		}
// 	// 	}
// 	// }
// 	// return total
// }

const headerTemplate = (data: {
	date: string,
	staffData: {
		staff_name: string
	}
}) => (
	<span className="text-gray-900 font-bold">{data.date
		? moment(data?.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '' }</span>
)

// const footerTemplate = (data: any) => (
// 	<>
// 		<td
// 			colSpan={7}
// 			style={{ textAlign: 'right' }}
// 			className="bg-gray-100 font-normal">
// 			Total Hours
// 		</td>
// 		{calculateTimesheetTotal(data)}
// 	</>
// )
