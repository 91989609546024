export interface NewsFeedMessage {
	id: number
	createdAt: string
	from: string
	attachment_url?: string
	message: string
	status: string
	fromStaffData: any
}

interface MessageProps {
	message: NewsFeedMessage
}

export function MessageCard({ message }: MessageProps) {
	return (
		<div className="bg-white rounded-lg overflow-hidden shadow-lg">
			<div className="p-6">
				<div className="bg-slate-200 rounded-t-lg p-4">
					<p className="text-xl font-semibold text-gray-800 mb-2">
						{message.fromStaffData.staff_name}
					</p>
					<p className="text-sm text-gray-600">{new Date(message.createdAt).toLocaleString('en-AU')}</p>
				</div>
				<div className="bg-slate-100 rounded-b-lg p-4 border-t-2 border-gray-300">
					<p className="text-gray-700 mb-4">{message.message}</p>
					{message.attachment_url && (
						<div className="mb-4">
							<div className="rounded-lg overflow-hidden border-2 border-blue-300">
								<img
									src={message.attachment_url}
									alt="Attached Image"
									className=" w-[640px] h-auto"
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
