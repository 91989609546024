import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useUpdateDayTimesheetById = () => {
	const { showError, showSuccess } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateDayTimesheet = async (id: number, data: unknown) => {
		try {
			const response = await postRequest(
				AppRoutes.serverDayTimesheetRoutes.updateTimesheet,
				data,
				id,
			)
			showSuccess('Day Timesheet updated successfully')
			queryClient.invalidateQueries(['day-timesheets'])
			return response.data
		} catch (error) {
			showError('Something went wrong when updating the timesheet')
			throw new Error('Something went wrong updating Day Timesheet')
		}
	}
	return { updateDayTimesheet }
}
