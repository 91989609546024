import {
	CreateFile,
	Dropdown,
	Input,
	MultiSelect,
	SideModal,
	TextArea,
} from 'common'
import { useFormik } from 'formik'
import { active_inactive_options } from 'models'
import { StaffServices } from 'services'
import { useCreateNewsFeed } from 'services/newsfeed'
import { useCreateNewsFeedMessage } from 'services/newsfeed_message'
import { OptionsForDropdownFilter } from 'utilities'
import * as Yup from 'yup'
interface IProps {
	news_feed_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	from: string | null
	to: string[]
	subject: string
	initial_message: string
	status: string
	uploaded_file: any
}

export const NewsFeedForm = ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	news_feed_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const initialValues: IInitialValues = {
		from: null,
		to: [],
		subject: '',
		initial_message: '',
		status: 'Active',
		uploaded_file: null,
	}

	const { data: staffData } = StaffServices.useStaff()
	const { createNewsFeedMessage } = useCreateNewsFeedMessage()
	const { createNewsFeed } = useCreateNewsFeed()

	const validationSchema = Yup.object({})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async () => {
			const newNewsFeed = {
				from: Number(formik.values.from),
				to: formik.values.to.map((item) => Number(item)),
				subject: formik.values.subject,
				status: formik.values.status,
			}

			const newsFeedResponse = await createNewsFeed(newNewsFeed)
			
			if (newsFeedResponse) {
				console.log(newsFeedResponse)
				const newNewsFeedMessage = {
					from: formik.values.from,
					newsfeed_id: newsFeedResponse.data.id,
					message: formik.values.initial_message,
					attachment_url: formik.values.uploaded_file,
					status: 'Active',
				}
				await createNewsFeedMessage(newNewsFeedMessage)
				setOpen(false)
			}
		},
	})

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between">
					<div className="w-full">
						<Dropdown
							id="from"
							label="From"
							options={OptionsForDropdownFilter(
								staffData,
								'id',
								['staff_name'],
								'status',
								'Active',
							)}
							value={formik.values.from}
							onChange={formik.setFieldValue}
							error={formik.errors.from}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between">
					<div className="w-full">
						<MultiSelect
							id="to"
							label="To"
							options={OptionsForDropdownFilter(
								staffData,
								'id',
								['staff_name'],
								'status',
								'Active',
							)}
							value={formik.values.to}
							onChange={formik.setFieldValue}
							error={formik.errors.to}
						/>
					</div>
				</div>
				<div className="flex items-center">
					<Input
						id="subject"
						type="text"
						value={formik.values.subject}
						error={formik.errors.subject}
						handleBlur={formik.handleBlur}
						handleChange={formik.handleChange}
						placeholder="Subject"
						title="Subject"
					/>
				</div>
				<div className="flex items-center justify-between">
					<div className="w-full">
						<TextArea
							id="initial_message"
							title="Initial Message"
							rows={4}
							type="text"
							error={formik.errors.initial_message}
							value={formik.values.initial_message}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>
				<CreateFile
					field="uploaded_file"
					setFieldValue={formik.setFieldValue}
					value={formik.values.uploaded_file}
				/>
				{/* <div className="flex items-center">
					<div className="w-1/2">
						<Input
							id="description"
							type="text"
							value={formik.values.description}
							error={formik.errors.description}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Description"
							title="Description"
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="work_order_number"
							type="text"
							value={formik.values.work_order_number}
							error={formik.errors.work_order_number}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Work Order Number"
							title="Work Order Number"
						/>
					</div>
				</div>
				<div className="flex items-center">
					<div className="w-1/2">
						<Input
							id="area"
							type="text"
							value={formik.values.area}
							error={formik.errors.area}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Area"
							title="Area"
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="sub_area"
							type="text"
							value={formik.values.sub_area}
							error={formik.errors.sub_area}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Sub Area"
							title="Sub Area"
						/>
					</div>
				</div>
				<div className="flex items-center">
					<div className="w-1/2">
						<DateSelect
							id="last_inspection"
							title="Last Inspection"
							value={formik.values.last_inspection}
							error={formik.errors.last_inspection}
							onChange={formik.setFieldValue}
						/>
					</div>
					<div className="w-1/2">
						<DateSelect
							id="inspection_due"
							title="Inspection Due"
							value={formik.values.inspection_due}
							error={formik.errors.inspection_due}
							onChange={formik.setFieldValue}
						/>
					</div>
				</div>
				<div className="flex items-center">
					<div className="w-1/2">
						<DateSelect
							id="dismantle_date"
							title="Dismantle Date"
							value={formik.values.dismantle_date}
							error={formik.errors.dismantle_date}
							onChange={formik.setFieldValue}
						/>
					</div>
				</div> */}
				<div className="flex items-center">
					<div className="w-full">
						<Dropdown
							id="status"
							label="Status"
							onChange={formik.setFieldValue}
							options={active_inactive_options}
							value={formik.values.status}
							onBlur={formik.setFieldTouched}
							error={formik.errors.status}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
