import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Button, ConfirmationDialog, Spinner, Table } from 'common'
import { AppRoutes } from 'config'
import { Link, useLocation } from 'react-router-dom'
import { K2Services } from 'services'
interface JobTaskTableProps {
	job_id?: number
	is_delivery?: boolean
	is_mobile_scaffold?: boolean
}

export const JobTaskTable = ({
	job_id,
	is_delivery = false,
	is_mobile_scaffold = false,
}: JobTaskTableProps) => {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } = K2Services.useJobTask(job_id)
	const { deleteTask } = K2Services.useDeleteJobTask()

	if (isLoading) {
		return <Spinner />
	}

	const columns = []

	if (!is_delivery && !is_mobile_scaffold) {
		const standardColumns = [
			{ field: 'zone', header: 'Zone' },
			{ field: 'zone_label', header: 'Zone Label' },
			{ field: 'type', header: 'Type' },
			{ field: 'description', header: 'Description' },
			{ field: 'total_hours', header: 'Total Hours' },
			{ field: 'percentage_erect', header: '% Erect' },
			{ field: 'percentage_dismantle', header: '% Dismantle' },
			{ field: 'percentage_complete', header: '% Completed' },
			{
				field: 'handover_certificate',
				header: 'Handover Certificate',
				body: (row: { handover_url: string }) => {
					if (row.handover_url) {
						return (
							<a href={row.handover_url} target="_blank" rel="noreferrer">
								Link
							</a>
						)
					}
					return <></>
				},
			},
			{ field: 'complete', header: 'completed' },
			{
				field: 'quote_id',
				header: 'Quote',
				body: (row: { quote_id: number }) => (
					<Link
						to={AppRoutes.privateRoutes.QuotesEdit.replace(
							':id',
							row.quote_id?.toString()
						)}>
						{row.quote_id}
					</Link>
				),
			},
			{
				field: 'Edit',
				header: 'Edit',
				body: (row: { id: number }) =>
					enableCreateUpdate ? (
						<Link
							to={{
								pathname: AppRoutes.privateRoutes.tasksEdit.replace(
									':id',
									row.id.toString()
								),
							}}
							state={{ background: location, name: 'editTask' }}>
							<PencilIcon className="h-4 w-4 text-gray-500" />
						</Link>
					) : (
						<></>
					),
			},
			{
				field: 'Delete',
				header: 'Delete',
				body: (row: { id: number }) =>
					enableCreateUpdate && !is_delivery ? (
						<ConfirmationDialog
							icon="danger"
							title="Delete Task"
							body="Are you sure you want to delete this task? This action is unrecoverable!"
							triggerButton={
								<button type="button">
									<TrashIcon className="h-4 w-4 text-gray-500" />
								</button>
							}
							confirmButton={
								<Button
									size="sm"
									variant="danger"
									onClick={async () => deleteTask(row.id)}>
									Delete
								</Button>
							}
						/>
					) : (
						<></>
					),
			},
		]
		columns.push(...standardColumns)
	} else if (is_delivery || is_mobile_scaffold) {
		const deliveryColumns = [
			{ field: 'zone_label', header: 'Type' },
			{ field: 'total_hours', header: 'Total Required' },
			{ field: 'total_returned', header: 'Total Returned' },
			{ field: 'total_damaged', header: 'Total Damaged / Missing' },
		]
		columns.push(...deliveryColumns)
	}

	return (
		<>
			<Table
				columns={columns}
				data={data.filter(
					(task: { task_type: string }) =>
						is_delivery || is_mobile_scaffold || task.task_type === 'Task'
				)}
				isLoading={isLoading ?? false}
				title={
					is_delivery
						? 'Fencing Tasks'
						: is_mobile_scaffold
						? 'Mobile Scaffold Tasks'
						: 'K2 Tasks'
				}
				disableButtons
				sortField="id"
				sortOrder={1}
			/>
		</>
	)
}
