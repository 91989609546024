import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import {
	ChatBubbleLeftRightIcon,
	DocumentArrowDownIcon,
	InboxArrowDownIcon,
	PencilSquareIcon,
} from '@heroicons/react/24/outline'
import { DataTable } from 'primereact/datatable'

import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { AppRoutes } from 'config'
import {
	DataTableServices,
	NewsFeedMessageServices,
	NewsFeedServices,
	StaffServices,
} from 'services'
import { NewsFeedForm } from 'components/NewsFeed'
import { FilterMatchMode } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'

export function NewsFeedTable() {
	const [openNewsFeedForm, setOpenNewsFeedForm] = useState(false)
	const { data, isLoading } = NewsFeedServices.useNewsFeeds()
	const { fetchAllNewsFeedMessagesByNewsFeedId } =
		NewsFeedMessageServices.noQuery_useNewsFeedMessagesByNewsFeedID()
	const { updateNewsFeedMessage } =
		NewsFeedMessageServices.useUpdateNewsFeedMessage()
	const { updateNewsFeed } = NewsFeedServices.useUpdateNewsFeed()
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()

	console.log(data)
	// const data = [
	// 	{
	// 		id: 1,
	// 		date_time: '31/01/2024 09:32:16',
	// 		subject: 'Test Subject',
	// 		from: 'ScaffM8 Service',
	// 		to: 'Keith Archer, Narelle Crockett',
	// 		status: 'Active',
	// 	},
	// ]

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		FilterColumn,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'status',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterInitialValue: 'Active',
				filterOptions: ['Active', 'Closed'],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equals,
			},
		],
		additionalGlobalFilterFields: ['id', 'date_time', 'subject', 'from', 'to'],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	const closeNewsFeed = (id: number) => {
		console.log('Marking news feed as closed', id)
		const newsFeedRecord = data?.find(
			(newsFeed: { id: number }) => newsFeed.id === id
		)
		if (newsFeedRecord) {
			updateNewsFeed({ ...newsFeedRecord, status: 'Closed' }, id)
		}
	}

	const openNewsFeed = (id: number) => {
		console.log('Marking news feed as active', id)
		const newsFeedRecord = data?.find(
			(newsFeed: { id: number }) => newsFeed.id === id
		)
		if (newsFeedRecord) {
			updateNewsFeed({ ...newsFeedRecord, status: 'Active' }, id)
		}
	}

	const syncNewsFeed = async (id: number) => {
		console.log('Updating news feed', id)
		const newsFeedRecord = data?.find(
			(newsFeed: { id: number }) => newsFeed.id === id
		)
		if (newsFeedRecord) {
			await updateNewsFeed({ ...newsFeedRecord, status: 'Active' }, id)
			const newsFeedMessages = await fetchAllNewsFeedMessagesByNewsFeedId(id)
			const promises = newsFeedMessages.map((message: any) =>
				updateNewsFeedMessage({ ...message }, message.id)
			)
			await Promise.all(promises)
		}
	}

	const getColorBySender = (sender: string): string => {
		const hashString = (str: string): number => {
			let hash = 8941
			for (let i = 0; i < str?.length; i++) {
				hash = (hash << 5) - hash + str.charCodeAt(i)
				hash |= 0 // Convert to 32-bit integer
			}
			return hash
		}

		const pastelColors = [
			'bg-blue-100',
			'bg-purple-100',
			'bg-green-100',
			'bg-yellow-100',
			'bg-pink-100',
			'bg-indigo-100',
			'bg-teal-100',
			'bg-orange-100',
			'bg-red-100',
			'bg-cyan-100',
			'bg-amber-100',
			'bg-lime-100',
			'bg-emerald-100',
			'bg-gray-100',
			'bg-blue-200',
			'bg-purple-200',
			'bg-green-200',
			'bg-yellow-200',
			'bg-pink-200',
			'bg-indigo-200',
			'bg-teal-200',
			'bg-orange-200',
			'bg-red-200',
			'bg-cyan-200',
			'bg-amber-200',
			'bg-lime-200',
			'bg-emerald-200',
			'bg-gray-200',
			// Add more colors as needed
		]

		const colorIndex = Math.abs(hashString(sender)) % pastelColors.length

		return pastelColors[colorIndex] || 'bg-gray-200' // Default color if sender is not found
	}

	console.log(data)

	return (
		<div className="card">
			<PageHeading
				title="News Feed"
				setOpen={setOpenNewsFeedForm}
				createBtn="Create News Feed"
			/>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={data}
					paginator
					showGridlines
					rows={100}
					rowsPerPageOptions={[25, 50, 100]}
					loading={false}
					dataKey="id"
					sortOrder={-1}
					sortField='id'
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					emptyMessage="No news feed items found.">
					<Column
						header="Message ID"
						field="id"
						style={{ minWidth: '10rem' }}
						body={(row) => (
							<div className="flex items-center">
								<Link
									to={AppRoutes.privateRoutes.NewsFeedDetails.replace(
										':id',
										row.id || ''
									)}>
									<div className="flex justify-center items-center hover:underline text-blue-500">
										<ChatBubbleLeftRightIcon className="w-4 h-4 mr-2 text-slate-500" />
										Message #{row.id}
									</div>
								</Link>
							</div>
						)}
					/>
					<Column
						header="Date/Time"
						field="createdAt"
						style={{ minWidth: '6rem' }}
						body={(row) => new Date(row.createdAt).toLocaleString('en-AU')}
					/>
					<Column
						header="Subject"
						field="subject"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						header="From"
						field="from"
						style={{ minWidth: '10rem' }}
						body={(row) => row.fromStaffData?.staff_name}
					/>
					<Column
						header="To"
						field="to"
						style={{ minWidth: '10rem' }}
						body={(row) =>
							row.to?.map((to: any, index: any) => {
								const staffMember = staffData?.find(
									(staff: any) => staff.id === Number(to)
								)
								return (
									<span
										key={index}
										className={`inline-block text-slate-700 mx-1 my-1 px-2 py-0.5 rounded-full ${getColorBySender(
											staffMember?.staff_name || 'Unknown'
										)}`}>
										{staffMember?.staff_name || 'Unknown'}
									</span>
								)
							})
						}
					/>
					<Column
						header="Status"
						field="status"
						bodyClassName="p-text-center"
						style={{ width: '6rem' }}
						body={(row) => <Badge type={row.status} text={row.status} />}
						{...FilterColumn.status}
					/>
					<Column
						field="operations"
						header="Operations"
						exportable={false}
						body={(row) => {
							return (
								<div className="flex-row space-y-2 mt-2 mb-2 items-start">
									{/* <div>
										<button className="flex items-center hover:bg-gray-100 p-1 rounded-md">
											<PencilSquareIcon className="h-4 w-4 text-yellow-900" />
											<span className="px-2 items-center text-sm font-medium text-amber-500 hover:text-yellow-700">
												Edit
											</span>
										</button>
									</div> */}
									{row.status === 'Active' && (
										<div>
											<button
												className="flex items-center hover:bg-gray-100 p-1 rounded-md"
												onClick={() => closeNewsFeed(row.id)}>
												<DocumentArrowDownIcon className="h-4 w-4 text-blue-900" />
												<span className="px-2 items-center text-sm font-medium text-blue-500 hover:text-blue-700">
													Mark Closed
												</span>
											</button>
										</div>
									)}
									{row.status === 'Closed' && (
										<div>
											<button
												className="flex items-center hover:bg-gray-100 p-1 rounded-md"
												onClick={() => openNewsFeed(row.id)}>
												<DocumentArrowDownIcon className="h-4 w-4 text-blue-900" />
												<span className="px-2 items-center text-sm font-medium text-blue-500 hover:text-blue-700">
													Mark Active
												</span>
											</button>
										</div>
									)}

									{/* <div>
										<button
											className="flex items-center hover:bg-gray-100 p-1 rounded-md"
											onClick={() => syncNewsFeed(row.id)}>
											<DocumentArrowDownIcon className="h-4 w-4 text-blue-900" />
											<span className="px-2 items-center text-sm font-medium text-blue-500 hover:text-blue-700">
												Force Sync
											</span>
										</button>
									</div> */}
									{/* <div>
										<button className="flex items-center hover:bg-gray-100 p-1 rounded-md">
											<InboxArrowDownIcon className="h-4 w-4 text-green-900" />
											<span className="px-2 items-center text-sm font-medium text-green-500 hover:text-green-700">
												Download
											</span>
										</button>
									</div> */}
								</div>
							)
						}}
					/>
				</DataTable>
			</Container>
			<NewsFeedForm
				formType="create"
				heading="Create News Feed"
				open={openNewsFeedForm}
				setOpen={setOpenNewsFeedForm}
			/>
		</div>
	)
}
